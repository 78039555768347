define(['app', 'accessibilityFocusHelper'], (app, accessibilityFocusHelper) => {

    const shopTheLook = () => {
        const component = {};

        const _config = {
            selectors: {
                shopTheLook: '.shopTheLook',
                shopTheLookWrapper: '.shopTheLook_wrapper',
                exit: '.shopTheLook_sideViewExit',
                sideView: '.shopTheLook_sideView',
                hotspot: '.shopTheLook_hotspot',
                subTitle: '.shopTheLook_productSubtitle',
                titleContainer: '.athenaProductBlock_titleContainer',
                shopTheLookMobileOverlay: '.shopTheLook_mobile_overlay'
            },
            classNames: {
                show: 'show',
                clicked: 'clicked',
                dataTop: 'data-top'
            },
            attributes: {
                dataOptionsSKU: 'data-options-sku',
                dataProductId: 'data-product-id'
            },
        };

        const _init = (element) => {
            component.element = element;
            component.shopTheLookWrapper = document.querySelector(component.config.selectors.shopTheLookWrapper);
            component.exit = component.element.querySelectorAll(component.config.selectors.exit);
            component.sideViews = component.element.querySelectorAll(component.config.selectors.sideView);
            component.hotspots = component.element.querySelectorAll(component.config.selectors.hotspot);
            component.subTitles =  component.element.querySelectorAll(component.config.selectors.subTitle);
            component.titleContainer = component.element.querySelector(component.config.selectors.titleContainer);
            component.shopTheLookMobileOverlay = component.element.querySelector(component.config.selectors.shopTheLookMobileOverlay);
            component.moveSideViews();
            component.addEventListeners();
            component.moveSubTitle();
            return component;
        };

        const _moveSideViews = () => {
            component.sideViews.forEach((elm, key=i) => {
                component.element.insertBefore(elm, component.element.childNodes[key]);
            })
        };

      const _getShownExit = () => {
        for (let i = 0; i < component.sideViews.length; i++) {
          if (component.sideViews[i].classList.contains(component.config.classNames.show)) {
            return component.sideViews[i].querySelector(component.config.selectors.exit);
          }
        }
      }

      const _addEventListeners = () => {
          component.hotspots && component.hotspots.forEach((item) => {
            item.addEventListener('click', () => {
              if (item.classList.contains(component.config.classNames.clicked)) {
                component.exitProductDetails(component.getShownExit());
              } else {
                item.classList.add(component.config.classNames.clicked);
                let itemId = item.getAttribute('data-options-sku');
                component.openProductDetails(itemId, component.hotspots);
              }
            });
          });

          component.exit && component.exit.forEach((item) => {
            item.addEventListener('click', () => {
              component.exitProductDetails(item);
            });
          });
        };

        const _openProductDetails = (productId, hotspots) => {
            let productTray = document.querySelector(component.config.selectors.sideView + '[data-product-id= "' + productId + '"]');
            let smallScreen = window.matchMedia("(max-width: 600px)");
            component.sideViews.forEach((item) => {
                if(item.getAttribute('data-product-id') !== productId) {
                    item.classList.remove(component.config.classNames.show);
                }
            });
            component.hotspots.forEach((item) => {
                if(item.getAttribute('data-options-sku') !== productId) {
                    item.classList.remove(component.config.classNames.clicked);
                }
            });

            let hotSpotsTopValues = [];

            hotspots.forEach((elm) => {
                hotSpotsTopValues.push(elm.offsetTop);
            });

            productTray.classList.add(component.config.classNames.show);
            accessibilityFocusHelper.focusAndScroll(productTray);

            component.shopTheLookMobileOverlay.classList.add(component.config.classNames.show);
            component.shopTheLookMobileOverlay.style.height = (productTray.clientHeight + 100) + "px";
            component.shopTheLookWrapper.style.minHeight = (productTray.clientHeight - component.shopTheLookWrapper.clientHeight) + component.shopTheLookWrapper.clientHeight + 100 + 'px';

        };

        const _exitProductDetails = (item) => {
            let smallScreen = window.matchMedia("(max-width: 600px)");
            item.parentElement.classList.remove(component.config.classNames.show);
            item.parentElement.parentElement.style.removeProperty('min-height');
            component.hotspots.forEach((item) => {
                if (smallScreen.matches) {
                    item.style.top = item.getAttribute('data-top');
                }
                item.classList.remove(component.config.classNames.clicked);
            });
            component.shopTheLookMobileOverlay.classList.remove(component.config.classNames.show);
            component.shopTheLookWrapper.style.minHeight = null;
        };

        const _moveSubTitle = () => {
            component.subTitles && component.subTitles.forEach ((item) => {
                item.parentElement.querySelector(component.config.selectors.titleContainer)
                                  .insertAdjacentElement('afterend', item);
            });
        };

        component.config = _config;
        component.init = _init;
        component.addEventListeners = _addEventListeners;
        component.openProductDetails = _openProductDetails;
        component.exitProductDetails = _exitProductDetails;
        component.moveSubTitle = _moveSubTitle;
        component.moveSideViews = _moveSideViews;
        component.getShownExit = _getShownExit;
        return component;
    };

    return shopTheLook;
});
